// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-get-premium-tsx": () => import("./../../../src/pages/get-premium.tsx" /* webpackChunkName: "component---src-pages-get-premium-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-methodologies-index-tsx": () => import("./../../../src/pages/our-methodologies/index.tsx" /* webpackChunkName: "component---src-pages-our-methodologies-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-report-tsx": () => import("./../../../src/pages/report.tsx" /* webpackChunkName: "component---src-pages-report-tsx" */),
  "component---src-pages-risks-tsx": () => import("./../../../src/pages/risks.tsx" /* webpackChunkName: "component---src-pages-risks-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-thank-you-success-tsx": () => import("./../../../src/pages/thank-you/success.tsx" /* webpackChunkName: "component---src-pages-thank-you-success-tsx" */),
  "component---src-pages-unlock-premium-tsx": () => import("./../../../src/pages/unlock-premium.tsx" /* webpackChunkName: "component---src-pages-unlock-premium-tsx" */),
  "component---src-templates-city-index-tsx": () => import("./../../../src/templates/city/index.tsx" /* webpackChunkName: "component---src-templates-city-index-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../../../src/templates/post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-risk-index-tsx": () => import("./../../../src/templates/risk/index.tsx" /* webpackChunkName: "component---src-templates-risk-index-tsx" */),
  "component---src-templates-state-index-tsx": () => import("./../../../src/templates/state/index.tsx" /* webpackChunkName: "component---src-templates-state-index-tsx" */)
}

