import React, { FC } from "react"
import "../styles/index.scss"

const StyleLayout : FC = ({ children }) => {
    return (
        <>
            {children}
        </>
    )
}

export default StyleLayout
