const React = require("react")
const StyleLayout = require("./src/components/StyleLayout").default
const loadableReady = require("@loadable/component").loadableReady

exports.wrapPageElement = ({ element, props }) => {
  return <StyleLayout {...props}>{element}</StyleLayout>
}

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback)
    })
  }
}
